<template>
  <div class="d-flex justify-center">
    <GenerateInvoices
      ref="generateInvoice"
      @close="closeDialog"
      :isOpen="isOpen"
    />
    <SecondaryButton
      @click.native="openDialog"
      permission="create"
      color="primary"
    >
      <div class="d-flex align-center justify-center mb-1">
        <inline-svg :src="require('@/assets/svg/invoice-white.svg')" /> +
      </div>
      Generate Invoice
    </SecondaryButton>
  </div>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";
import GenerateInvoices from "@/views/finance/components/Generate/GenerateInvoices";

export default {
  name: "GenerateInvoiceAction",
  components: {
    InlineSvg,
    SecondaryButton,
    GenerateInvoices
  },
  data() {
    return {
      isOpen: false,
      contractType: null
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
      document.title = "Generate Invoice";
    },
    closeDialog() {
      this.isOpen = false;
      document.title = "Invoices";
    }
  }
};
</script>
