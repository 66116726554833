<template>
  <div class="list-item with-icon mb-1" :class="{ selected: isSelected }">
    <v-list-item-avatar min-width="60px" height="60px" tile>
      <v-img height="30px" contain src="@/assets/svg/invoice.svg" />
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="item-right">
        <div class="description py-1" :class="{ 'flex-column': isMobile }">
          <div class="d-flex align-center mb-1">
            <h3>{{ invoice.issuer }}</h3>
            <v-img
              class="mx-2"
              height="15px"
              contain
              src="@/assets/svg/arrow-right-black.svg"
            />
            <h3>{{ invoice.recipient }}</h3>
          </div>
          <div class="d-flex align-center">
            <span class="text-mono">
              {{ invoice.total | cost | currency(invoice.currencyCode) }}
            </span>
            <span class="ml-1" v-if="!invoice.clientInvoiceSet">
              | to {{ invoice.periodEnd | fullDate }}
            </span>
          </div>
        </div>
        <div
          class="d-flex"
          :class="{ 'flex-column': isMobile, 'align-center': !isMobile }"
        >
          <div class="d-flex flex-column" :class="{ 'align-end': !isMobile }">
            <span class="text-mono mb-1">{{ invoice.invoiceNumber }}</span>
            <Chip
              :color="getColorByStatus(invoice.state)"
              class="chip--status"
              shrink
            >
              {{ invoice.state | upperCase }}
            </Chip>
          </div>
          <div
            class="download d-flex ml-2 my-2"
            :class="{ 'justify-center': !isMobile }"
          >
            <v-menu max-width="360" v-if="invoice.state === 'issued'">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-img
                    class="download__icon"
                    height="20px"
                    contain
                    src="@/assets/svg/download.svg"
                  />
                </div>
              </template>
              <v-list dark>
                <v-list-item-group>
                  <v-list-item v-for="item in options" :key="item">
                    <v-list-item-content>
                      <v-list-item-title @click="downloadFile(item)">
                        {{ item }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-progress-circular v-else indeterminate color="amber" size="30" />
          </div>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
import Chip from "@/components/common/Chip";
import { getColorByStatus } from "@/utils/invoices";
import { fileToDownload } from "@/utils/helpers";

export default {
  components: {
    Chip
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    isSelectMultiple: Boolean,
    isSelected: Boolean
  },
  data() {
    return {
      options: ["Download PDF", "Download CSV"]
    };
  },
  computed: {},
  methods: {
    getColorByStatus,
    downloadFile(option) {
      const file =
        option === "Download PDF"
          ? this.invoice.invoicePdf
          : this.invoice.invoiceCsv;
      fileToDownload(file);
    }
  }
};
</script>

<style lang="scss">
.invoices,
.payroll {
  .list-item {
    border-radius: 5px;
    padding: 0;
  }

  .list-item .v-avatar.v-list-item__avatar {
    background-color: transparentize($primary, 0.9);
  }

  .download {
    width: 40px;
    &__icon {
      cursor: pointer;
    }
  }
}
</style>
