import moment from "moment";
import { reduce, find, groupBy, map } from "lodash";

export const INVOICE_STATUSES = {
  PENDING: "pending",
  PROCESSING: "processing",
  DRAFT: "draft",
  ISSUED: "issued"
};

export const getColorByStatus = status => {
  switch (status) {
    case INVOICE_STATUSES.PENDING:
      return "gray";
    case INVOICE_STATUSES.PROCESSING:
      return "yellow";
    case INVOICE_STATUSES.DRAFT:
      return "gray";
    case INVOICE_STATUSES.ISSUED:
      return "green";
    default:
      return "";
  }
};

export const formatInvoicesDate = invoices =>
  invoices.map(invoice => ({
    ...invoice,
    date: moment(invoice.date).format("DD/MM/YYYY")
  }));

export const getInvoiceSets = invoices => {
  return reduce(
    invoices,
    (grouped, invoice) => {
      const propertyName = "client-invoice-sets";
      let set = invoice[propertyName];
      if (set) {
        const groupedBySet = find(grouped, { id: set.id });
        if (!groupedBySet) {
          grouped.push(set);
        }
      }
      return grouped;
    },
    []
  );
};

export const groupInvoiceBySet = invoices => {
  let invoiceSets = getInvoiceSets(invoices);
  const groupedInvoices = groupBy(invoices, "clientInvoiceSet");
  map(invoiceSets, set => {
    set.invoices = groupedInvoices[set.id];
  });
  invoiceSets.push({ id: 0, invoices: groupedInvoices[null] });
  return invoiceSets;
};
